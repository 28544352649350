'use client';

import { useFormStatus } from 'react-dom';
import { type ComponentProps } from 'react';
import { Button } from '@/components/ui/button';
type Props = ComponentProps<'button'> & {
  pendingText?: string;
};
export function SubmitButton({
  children,
  pendingText,
  ...props
}: Props) {
  const {
    pending,
    action
  } = useFormStatus();
  const isPending = pending && action === props.formAction;
  return /* @ts-ignore */<Button {...props} type="submit" aria-disabled={pending} data-sentry-element="Button" data-sentry-component="SubmitButton" data-sentry-source-file="submit-button.tsx">
      {isPending ? pendingText : children}
    </Button>;
}