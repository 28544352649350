'use client';

import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { SubmitButton } from './submit-button';
import { useEffect, useTransition, use } from 'react';
import { Loader2 } from 'lucide-react';
import { signInWithGoogle, signInWithOtp } from './actions';
import { track } from '@vercel/analytics';
import { useTranslations } from 'next-intl';
import { useToast } from '@/components/ui/use-toast';
import { useRouter } from '@/i18n/routing';
export default function LoginPage(props: {
  searchParams: Promise<{
    error?: string;
    redirectURI?: string;
  }>;
}) {
  const searchParams = use(props.searchParams);
  const t = useTranslations('auth.login');
  const [isPending, startTransition] = useTransition();
  const redirectURI = encodeURIComponent(searchParams?.redirectURI || '/');
  const {
    toast
  } = useToast();
  const router = useRouter();
  useEffect(() => {
    if (searchParams.error) {
      track('login/error', {
        error: searchParams.error
      });
      toast({
        title: t('error.title'),
        description: t('error.description'),
        variant: 'destructive'
      });

      // shallowly remove the error from the URL
      const url = new URL(window.location.href);
      url.searchParams.delete('error');
      window.history.replaceState(null, '', url.toString());
    }
  }, []);
  const loginWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    track('login/start_email');
    e.preventDefault();
    startTransition(() => signInWithOtp(new FormData(e.currentTarget), redirectURI));
  };
  const loginWithGoogle = async (e: React.FormEvent<HTMLFormElement>) => {
    track('login/start_google');
    e.preventDefault();
    startTransition(() => {
      signInWithGoogle(redirectURI);
    });
  };
  return <div className="mx-auto max-w-md space-y-6 py-12 px-4" data-sentry-component="LoginPage" data-sentry-source-file="page.tsx">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold">{t('title')}</h1>
        <p className="text-muted-foreground">{t('description')}</p>
      </div>
      <div className="space-y-4">
        <form onSubmit={loginWithGoogle}>
          <Button disabled={isPending} variant="outline" className="w-full bg-red-500 text-white hover:bg-red-600 hover:text-white" data-sentry-element="Button" data-sentry-source-file="page.tsx">
            <ChromeIcon className="mr-2 h-5 w-5" data-sentry-element="ChromeIcon" data-sentry-source-file="page.tsx" />
            {isPending ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : t('google_login')}
          </Button>
        </form>
        <Separator data-sentry-element="Separator" data-sentry-source-file="page.tsx" />
        <form onSubmit={loginWithEmail} className="space-y-2">
          <Label htmlFor="email" data-sentry-element="Label" data-sentry-source-file="page.tsx">Email</Label>
          <Input id="email" type="email" name="email" placeholder={t('email_placeholder')} required data-sentry-element="Input" data-sentry-source-file="page.tsx" />
          <SubmitButton disabled={isPending} type="submit" className="w-full !mt-4" data-sentry-element="SubmitButton" data-sentry-source-file="page.tsx">
            {isPending ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : t('submit_magic_link')}
          </SubmitButton>
        </form>
      </div>
    </div>;
}
function ChromeIcon(props: any) {
  return <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="svg" data-sentry-component="ChromeIcon" data-sentry-source-file="page.tsx">
      <circle cx="12" cy="12" r="10" data-sentry-element="circle" data-sentry-source-file="page.tsx" />
      <circle cx="12" cy="12" r="4" data-sentry-element="circle" data-sentry-source-file="page.tsx" />
      <line x1="21.17" x2="12" y1="8" y2="8" data-sentry-element="line" data-sentry-source-file="page.tsx" />
      <line x1="3.95" x2="8.54" y1="6.06" y2="14" data-sentry-element="line" data-sentry-source-file="page.tsx" />
      <line x1="10.88" x2="15.46" y1="21.94" y2="14" data-sentry-element="line" data-sentry-source-file="page.tsx" />
    </svg>;
}